@import url(./variables.css);

.landing-main-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.landing-sub-container {
  display: flex;
  width: 100%;
  padding-top: 1rem;
  padding-left: 1rem;
  flex-direction: column;
}

.blob {
  height: 35rem;
  width: 35rem;
  margin-top: -3rem;
}

.blob-quote {
  position: absolute;
  max-width: 13rem;
  z-index: 3;
  top: 3rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.landing-subheader {
  /* margin: 2rem 0rem */
}

.quote-author {
  color: var(--dark-primary);
}

.blob-container {
  margin-left: 3rem;
  position: relative;
  display: flex;
  justify-content: center;
  animation-name: blob;
  animation-duration: 2s;
}

.github-container {
  display: flex;
  align-items: center;
  padding-top: 1rem;
}

.landing-title-container {
  width: 100%;
  display: flex;
}

.landing-github-button {
  height: 3.25rem;
  width: 8rem;
  font-weight: bold;
  font-size: 1.75rem;
  margin-right: 1rem;
  background: var(--dark-highlight);
  color: var(--light-highlight);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.25rem;
}

.landing-github-button:hover {
  animation: grow 1s;
  animation-fill-mode: forwards;
}

.landing-header {
  color: var(--dark-primary);
  font-weight: bold;
  font-size: 4.5rem;
  line-height: 1;
  /* background: blue; */
  margin-top: 3rem;
  margin-bottom: 2rem;
  /* color: hsl(var(--hue), 95%, 22%); */

  /* margin-left: 2rem; */
  /* width: 18rem; */
}

.landing-subheader {
  color: var(--dark-primary);
  /* margin-top: -.25rem; */
  font-weight: bold;
  animation-name: subheader;
  animation-duration: 1s;
  /* margin-left: 2rem; */
}

.landing-svg {
  color: var(--dark-highlight);
  font-size: 55px;
  margin-right: 1.5rem;
}
.landing-svg:hover {
  color: var(--light-highlight);
}

.landing-svg:hover {
  transform: scale(1.1);
  transition-duration: 1s;
  cursor: pointer;
}

.landing-dark {
  background: black;
}

.refresh-button {
  cursor: pointer;
  transform: rotatey(180deg);
  font-size: 2rem;
  position: absolute;
  top: -4rem;
  left: 37%;
  color: pink;
}

.refresh-button-animate {
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

.quote-content-container {
  display: flex;
  flex-direction: column;
  background: pink;
}

@keyframes spin {
  0% {
    transform: rotate(180deg) rotateY(180deg);
  }
  100% {
    transform: rotate(360deg) rotateY(180deg);
  }
}
/* .github-container {
    margin-left: 2rem;
} */

/* .github-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background: yellow;
    margin-top: 5rem;
    height: 6rem;
    width: 18rem;
    line-height: 1.5;
} */

.cta-text {
  color: var(--dark-primary);
}
.logo-container {
  display: flex;
  align-items: center;
  /* background: green */
}

.eyeballs {
  font-size: 1.25rem;
  animation: move 3s ease-in;
  animation-iteration-count: infinite;
}

.big-icon {
  display: none;
  margin-right: 3rem;
  margin-top: 5rem;
  font-size: 16rem;
  animation: color 30s;
  animation-iteration-count: infinite;
}

.landing-first-name {
  animation-name: introright;
  animation-duration: 0.5s;
}
.landing-last-name {
  animation-name: introleft;
  animation-duration: 0.5s;
}

@keyframes introright {
  0% {
    margin-left: -2rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes introleft {
  0% {
    margin-left: 3rem;
  }
  100% {
    margin-left: 0;
  }
}

/* 
@keyframes color {
    0% {
        color: var(--dark-primary)
    }

    50% {
        color: var(--dark-highlight)
    }

    100% {
        color: var(--dark-primary)
    }
}

*/

@keyframes grow {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes move {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(7px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes blob {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes subheader {
  0% {
    transform: scale(0.75);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 850px) {
  .blob {
    display: none;
  }
  .blob-quote {
    display: none;
  }
}

@media (min-width: 375px) {
  .landing-sub-container {
    padding-left: 3rem;
  }
}
@media (min-width: 550px) {
  .landing-header {
    font-size: 5rem;
  }
  .themes-items-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 650px) {
  .landing-sub-container {
    padding-left: 4rem;
  }
}
@media (min-width: 800px) {
  .landing-sub-container {
    padding-left: 5rem;
  }
}

@media (min-width: 850px) {
  .blob {
    height: 25rem;
    width: 25rem;
  }
  .blob-quote {
    top: 5.5rem;
    right: 6.5rem;
  }
  /* .github-container {
        margin-top: 0;
    } */
}

@media (min-width: 885px) {
  .landing-header {
    font-size: 5.5rem;
  }
  .big-icon {
    display: flex;
  }
}
@media (min-width: 900px) {
  .landing-sub-container {
    padding-left: 6rem;
  }
}
@media (min-width: 1000px) {
  .landing-sub-container {
    padding-left: 7.5rem;
  }
}
@media (min-width: 1050px) {
  .landing-sub-container {
    padding-left: 7.5rem;
  }
  .landing-header {
    font-size: 6rem;
  }
  .blob {
    height: 28rem;
    width: 28rem;
  }
  .blob-quote {
    top: 6.5rem;
    right: 8.5rem;
  }
}
@media (min-width: 1200px) {
  .landing-sub-container {
    padding-left: 9.5rem;
  }
  .landing-header {
    font-size: 7rem;
  }
  .landing-subheader {
    font-size: 1.5rem;
  }
  /* .github-container {
        margin-top: -3.5rem;
    } */
  .blob {
    height: 30rem;
    width: 30rem;
    margin-top: -3rem;
    margin-left: 3rem;
  }

  .blob-quote {
    position: absolute;
    top: 8rem;
    max-width: 15rem;
    right: 8.5rem;
    z-index: 3;
  }
}
@media (min-width: 1400px) {
  .landing-sub-container {
    padding-left: 11.5rem;
  }
  .landing-header {
    font-size: 8rem;
  }
  .landing-subheader {
    font-size: 1.75rem;
  }

  /* .github-container {
        margin-top: -3.5rem;
    } */
  .blob {
    height: 35rem;
    width: 35rem;
    margin-top: -3rem;
    margin-left: 3rem;
  }

  .blob-quote {
    position: absolute;
    top: 10rem;
    max-width: 15rem;
    right: 11.5rem;
    z-index: 3;
  }
}
