.featured-project-main-container {
    width: 100%;
    min-height: 100vh;
    padding-bottom: 4rem;
}
.featured-project-items-container {
    width: 100%;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
}

.featured-project-boxes-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.featured-project-container {
    width: 18rem;
    margin: 1.5rem 0rem;
}

.project-title {
    color: var(--dark-highlight);
    font-size: 2rem;
}
.project-title:hover {
    text-decoration: underline;
    cursor: pointer;
}

.projects-container {
    display: flex;
    flex-direction: column;
}

.project-item {
    list-style-type: none;
}


.yay {
    height: 10rem;
    width: 10rem;
    background: red;
}


.featured-project-paragraph {
    padding: 0rem 2rem;
    text-align: center;
    line-height: 1.5rem;
}

.project-card {
    cursor: pointer;
        height: 20rem;
        width: 17rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding: 1.5rem 1.5rem;
        border-radius: 5px;
        background: var(--dark-primary);
        color: white;
        margin: 1rem;
        text-align: center;
}

.project-name {
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 1.5rem;
}


.featured-project-header {
    color: var(--dark-highlight);
    margin-bottom: 2rem;
}

@media (min-width: 450px) {
    .featured-project-container {
        width: 23rem;
    }
    .featured-project-paragraph {
        max-width: 24rem;
    }
}
@media (min-width: 600px) {
    .featured-project-container {
        width: 35rem;
    }

    .featured-project-paragraph{
        max-width: 36rem;
    }
}

@media (min-width: 800px) {
    .featured-project-container {

        width: 43rem;
    }
    .featured-project-paragraph{
        max-width: 46rem;
    }
}